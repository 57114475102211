import React from 'react';
import { graphql } from 'gatsby';

import Img from 'gatsby-image';

import SEO from '../components/seo/seo';
import Layout from '../components/layout/layout';
import Location from '../components/location-banner/location-banner';
import Adress from '../components/adress/adress';

import FormContainer from '../components/form-container/form-container';

export default ( props ) => {

  const adressData = {
    img: 
      <Img 
        alt="Building" 
        fluid={props.data.placeholder.childImageSharp.fluid} 
      />,
  };

  return (
    <Layout>
      <SEO 
        title="Contact" 
        keywords={[`digital`, `agency`, `technology`, `Radium`, `Contact`]} 
      />
      <Location />
      <Adress {...adressData}/>
      <FormContainer />
    </Layout>
  )
};

export const contactQuery = graphql`
  query {
    placeholder: file(sourceInstanceName: {eq: "images"}, relativePath: { eq: "placeholder600x400.png" }) {
      ...fluidNormal
    }
  }
`;