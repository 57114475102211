import React from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import { FaRegSmile, FaRegFrown, FaCheck, FaTimes } from 'react-icons/fa';
import { MdLoop } from 'react-icons/md';

import Styles from './form-template.module.scss';

class FormTemplate extends React.Component {

  /**
   * Available from parent in props
   * @param onValidated
   * @param status
   * @param message used for debugging purposes
   */
  constructor(props) {
    super(props)

    this.name = '';
    this.email = '';
    
    this.state = {
      nameValid: null,
      emailValid: null,
      capchaValid: null
    }

    this.validationName = this.validationName.bind( this )
    this.validationEmail = this.validationEmail.bind( this )
    this.capcha = this.capcha.bind( this )
    this.submit = this.submit.bind( this )
  }

  submit() {
    this.email &&
    this.name &&
    this.email.value.indexOf("@") > -1 &&
    this.props.onValidated({
      EMAIL: this.email.value,
      NAME: this.name.value
    })
  }

  validationName() {

    if (this.name.value) {
      this.setState( { nameValid: 'valid'} )
    } else {
      this.setState( { nameValid: 'error'} )
    }
  }

  validationEmail() {

    const regEx = /\w+@\w+\.\w+/;

    if (regEx.test(this.email.value)) {
      this.setState( { emailValid: 'valid'} )
    } else {
      this.setState( { emailValid: 'error'} )
    }
  }

  capcha() {
    this.setState( { capchaValid: 'valid'} )
  }
  
  render() {
    
    return (
      <div className={Styles.formContainer}>
        <div className={Styles.formInputContainer}>
          <div className={Styles.formInputName}>
            <label 
              className={Styles.formLabel}
              htmlFor="name"
            >
              Name 
              <span className={Styles.formRequire}>*</span>
            </label>
            <input
              name="name"
              className={Styles.formName}
              ref={node => (this.name = node)}
              type="text"
              maxLength="70"
              required
              onChange={this.validationName}
              style={ 
                this.state.nameValid === "error" ?
                { borderColor: "red" } : {}
              }
            />

            {this.state.nameValid === "valid" && 
              <div className={Styles.formCheckGood}>
                <FaCheck />
              </div>
            }
            {this.state.nameValid === "error" && 
              <div className={Styles.formCheckBad}>
                <FaTimes />
              </div>
            }
  
          </div>
          <div className={Styles.formInputEmail}>
            <label 
              className={Styles.formLabel}
              htmlFor="email"
            >
              Email
              <span className={Styles.formRequire}>*</span>
            </label>
            <input
              name="email"
              className={Styles.formEmail}
              ref={node => (this.email = node)}
              type="email"
              maxLength="50"
              required
              onChange={this.validationEmail}
              style={ 
                this.state.emailValid === "error" ?
                { borderColor: "red" } : {}
              }
            />

            {this.state.emailValid === "valid" && 
              <div className={Styles.formCheckGood}>
                <FaCheck />
              </div>
            }
            {this.state.emailValid === "error" && 
              <div className={Styles.formCheckBad}>
                <FaTimes />
              </div>
            }

          </div>
        </div>
  
        <div className={Styles.formSubmitContainer}>
          
          {(this.state.nameValid === "valid" && 
            this.state.emailValid === "valid" &&
            this.state.capchaValid === "valid") ?
            <button 
              className={`button__content ${Styles.formSubmit}`}
              onClick={this.submit}
            >
              Follow
            </button> : <button 
              className={`button__content ${Styles.formSubmitDisabled}`}
              onClick={this.submit}
            >
              Follow
            </button> 
          }
 
        </div>
  
        <div 
          className={`${Styles.formResponse}
            ${this.props.status ? Styles.formResponseActive : ''} `
          }
        >
          {this.props.status === "sending" && 
            <div className={Styles.formLoading}>
              <MdLoop />
            </div>
          }
          {this.props.status === "error" && 
            <div className={Styles.formError}>
              <FaRegFrown />
              <p>Something went wrong...</p>
            </div>
          }
          {this.props.status === "success" && 
            <div className={Styles.formSuccess}>
              <FaRegSmile />
              <p>Thank you for following us!!</p>
            </div>
          }
        </div>
  
        <p className={Styles.formInfoRequire}>
          <span className={Styles.formInfoRequireSpan}>*</span>Required field
        </p>
        <ReCAPTCHA 
          sitekey="6Lcsn6IUAAAAAL3vXWrpSjrTIN4EXgi2sC7GYopt"
          onChange={this.capcha}
        />

      </div>
    );
  }

};

export default FormTemplate;
