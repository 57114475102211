import React from 'react';
import MailchimpSubscribe from 'react-mailchimp-subscribe';
import { FaEnvelope } from 'react-icons/fa';

import FormTemplate from '../form-template/form-template';
import Styles from './form-container.module.scss';

export default () => {

  const url = 'https://radiumagency.us20.list-manage.com/subscribe/post?u=1202e0be73cd964041bd0116d&amp;id=7cec831491' ;

  return (

    <section className={Styles.mailchimpSection}>
      <div className="container">
        <div className={Styles.mailchimpTitleContainer}>
          <h2 className={Styles.mailchimpTitle}>
            Stay up to date
          </h2>
          <div className={Styles.mailchimpIcon}>
            <FaEnvelope />
          </div>
        </div>
        <div className={Styles.mailchimpCustomForm}>
          <MailchimpSubscribe 
            url={url} 
            render={({ subscribe, status, message }) => (
              <FormTemplate
                status={status}
                message={message}
                onValidated={formData => subscribe(formData)}
              />
            )}
          />
        </div>
      </div>
    </section>


  );

};
